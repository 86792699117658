import styled from "styled-components"
import BackgroundImage from "assets/img/svg/bg_4.svg"

export const Container = styled.div`
  padding: 96px 0;
  background-image: url(${BackgroundImage});
  background-size: auto 100%;
  background-position: right;
  background-repeat: no-repeat;
  background-color: #5618a4;
  position: relative;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 64px 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 48px 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    padding: 48px 0;
  }
`
