import PipeSvg                                                                                                                            from "assets/img/PageHeader/pipe.svg"
import PlusSvg                                                                                                                            from "assets/img/PageHeader/x.svg"
import {PrimaryButton}                                                                                                                    from "components/Buttons"
import {TitleSvg}                                                                                                                         from "components/TitleSvg"
import {HashHeader}                                                                                                                       from "components/Typography"
import Image                                                                                                                              from "gatsby-image"
import React                                                                                                                              from "react"
import {useHeroSliderData}                                                                                                                from "shared/Hooks/HeroSlider"
import {Swiper, SwiperSlide}                                                                                                              from "swiper/react"
import {BackgroundImage, Button, ButtonsWrapper, Container, Header, ImageBox, Pagination, SliderNavigation, SlideWrapper, Text, TextBox,} from "./styles"

export const Hero = () => {
  const slides = useHeroSliderData()

  const options = {
    loop:       true,
    speed:      600,
    autoplay:   {
      delay: 4000,
    },
    effect:     "fade",
    fadeEffect: {
      crossFade: true,
    },
    pagination: {
      el:           ".swiper-pagination",
      clickable:    true,
      renderBullet: function(index, className) {
        return `
          <span class="pagination_button ${className}">
            <span class="pagination_index">0${index + 1}.</span>
            <span class="pagination_title">${slides[index].pagination}</span>
          </span>
        `
      },
    },
    navigation: {
      nextEl: ".hero-slider-next",
      prevEl: ".hero-slider-prev",
    },
  }

  return (
    <Container>
      <Swiper {...options}>
        {slides.map((slide, i) => (
          <SwiperSlide key={i}>
            <SlideWrapper>
              <TextBox>
                <HashHeader color="#fff">światbezbarier</HashHeader>
                <Header>{slide.title}</Header>
                <Text>{slide.subtitle}</Text>
                <PrimaryButton to={slide.slug} type="right_arrow">
                  Dowiedz się więcej
                </PrimaryButton>
              </TextBox>
              {!slide.backgroundImage && (
                <ImageBox>
                  <TitleSvg
                    image={PlusSvg}
                    bottom="10%"
                    right="30%"
                    duration="5s"
                  />
                  <TitleSvg
                    image={PipeSvg}
                    top="15%"
                    left="15%"
                    duration="3s"
                    rotation="110deg"
                  />
                  <Image fluid={slide.image.childImageSharp.fluid}/>
                </ImageBox>
              )}
            </SlideWrapper>
            {slide.backgroundImage && (
              <>
                <BackgroundImage>
                  <Image
                    style={{width: "100%", height: "100%"}}
                    imgStyle={{
                      width:     "100%",
                      height:    "100%",
                      objectFit: "cover",
                    }}
                    fixed={slide.image.childImageSharp.fixed}
                  />
                </BackgroundImage>
              </>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
      <SliderNavigation>
        <Pagination className="swiper-pagination"/>
        <ButtonsWrapper>
          <Button className="hero-slider-prev"/>
          <Button className="hero-slider-next"/>
        </ButtonsWrapper>
      </SliderNavigation>
    </Container>
  )
}
