import styled from "styled-components"
import { GlobalWrapper, SectionWrapper } from "shared/Styles/Wrappers"

import Background from "assets/img/svg/bg_1.svg"

import PrevIcon from "assets/img/svg/prev_white.svg"
import NextIcon from "assets/img/svg/next_white.svg"

import PrevIconHover from "assets/img/svg/prev_white_hover.svg"
import NextIconHover from "assets/img/svg/next_white_hover.svg"

export const Container = styled(GlobalWrapper)`
  height: calc(100vh - 80px);
  background-color: rgb(86, 24, 164);
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-position: right center;
  background-size: auto 100%;
  position: relative;
  .swiper-container {
    height: 100%;
    width: 100%;
    .swiper-wrapper {
      .swiper-slide {
        width: 100%;
        height: 100%;
        padding: 100px 0;
        position: relative;
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    height: calc(100vh - 60px);
    min-height: 470px;
    .swiper-container {
      .swiper-wrapper {
        .swiper-slide {
          padding: 40px 0 100px 0;
        }
      }
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    height: 400px;
    min-height: 400px;
    .swiper-container {
      .swiper-wrapper {
        .swiper-slide {
          padding: 30px 0 80px 0;
        }
      }
    }
  }
  @media screen and (max-width: 813px) and (orientation: landscape) {
    height: calc(100vh - 60px);
    min-height: 315px;
  }
  @media screen and (max-width: 571px) and (orientation: landscape) {
    height: calc(100vh - 60px);
    min-height: 260px;
  }
  @media screen and (max-width: 600px) and (orientation: portrait) {
    height: calc(100vh - 60px);
    background-image: none;
  }
  @media screen and (max-width: 365px) {
    min-height: 500px;
  }
`

export const SlideWrapper = styled(SectionWrapper)`
  height: 100%;
  display: flex;
  position: relative;
  @media screen and (max-width: 600px) and (orientation: portrait) {
    flex-direction: column;
  }
`

export const Header = styled.h3`
  font-weight: bold;
  font-size: 50px;
  line-height: 1;
  color: #ffffff;
  margin-top: 96px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 36px;
    margin-top: 46px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 24px;
  }
  @media screen and (max-width: 813px) and (orientation: landscape) {
    font-size: 20px;
    margin-top: 12px;
  }
  @media screen and (max-width: 600px) and (orientation: portrait) {
    font-size: 26px;
    margin-top: 24px;
  }
  @media screen and (max-width: 600px) and (orientation: portrait) {
  }
`

export const Text = styled.p`
  font-size: 20px;
  line-height: 1.3;
  font-weight: 700;
  max-width: 500px;
  width: 100%;
  margin: 40px 0 30px 0;
  color: #fff;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 16px;
    max-width: 300px;
  }
  @media screen and (mcax-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 14px;
  }
  @media screen and (max-width: 813px) and (orientation: landscape) {
    font-size: 12px;
    margin: 24px 0;
  }
  @media screen and (max-width: 600px) and (orientation: portrait) {
    font-size: 14px;
    margin: 20px 0;
  }
`

export const TextBox = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  z-index: 3;

  @media screen and (max-width: 600px) and (orientation: portrait) {
    width: 100%;
    height: 50%;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-top: 24px;
  }
`
export const ImageBox = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .gatsby-image-wrapper {
    width: 100%;
    height: auto;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 0 30px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 15px;
  }
  @media screen and (max-width: 600px) and (orientation: portrait) {
    width: 100%;
    height: 50%;
    padding-bottom: 80px;
    padding-top: 60px;
    .gatsby-image-wrapper {
      width: 300px;
      height: auto;
    }
  }
  @media screen and (max-width: 330px) {
    height: 50%;
  }
`

export const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(38, 38, 38, 0.5);
  }
`

export const SliderNavigation = styled(SectionWrapper)`
  position: absolute;
  bottom: 64px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding: 10px 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    bottom: 24px;
    padding: 10px 24px;
  }
  @media screen and (max-width: 600px) and (orientation: portrait) {
    bottom: 48px;
  }

  @media screen and (max-width: 600px) and (orientation: landscape) {
    padding: 30px 30px 0;
  }
`

export const Pagination = styled.div`
  position: relative;
  text-align: left;
  display: flex;
  .swiper-pagination-bullet {
    width: auto;
    height: auto;
    border-radius: none;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    color: #fff;
    opacity: 0.4;
    transition: 0.2s all ease;
    &:not(:last-child) {
      margin-right: 64px;
    }
    &:hover {
      opacity: 0.6;
    }
    &.swiper-pagination-bullet-active {
      opacity: 1;
    }
    .pagination_index {
      font-size: 20px;
    }
    .pagination_title {
      font-size: 16px;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    .swiper-pagination-bullet {
      &:not(:last-child) {
        margin-right: 48px;
      }
      .pagination_index {
        font-size: 16px;
      }
      .pagination_title {
        font-size: 12px;
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    .swiper-pagination-bullet {
      &:not(:last-child) {
        margin-right: 36px;
      }
      .pagination_index {
        font-size: 14px;
      }
      .pagination_title {
        font-size: 10px;
      }
    }
  }
  @media screen and (max-width: 600px) and (orientation: portrait) {
    .swiper-pagination-bullet {
      &:not(:last-child) {
        margin-right: 14px;
      }

      .pagination_title {
        display: none;
      }
    }
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
`

export const Button = styled.div`
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  position: relative;
  &.hero-slider-prev {
    background-image: url(${PrevIcon});
    margin-right: 12px;
    &:hover {
      background-image: url(${PrevIconHover});
    }
  }
  &.hero-slider-next {
    background-image: url(${NextIcon});
    &:hover {
      background-image: url(${NextIconHover});
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: 30px;
    height: 30px;
    &.hero-slider-prev {
      margin-right: 8px;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 24px;
    height: 24px;
    &.hero-slider-prev {
      margin-right: 6px;
    }
  }
`