import React from "react"

import {
  Wrapper,
  Column,
  TextBox,
  StyledHash,
  StyledHeader,
  StyledSubHeader,
  StyledParagraph,
} from "./styles"

import { PrimaryButton } from "components/Buttons"

export const SectionLayout = ({ data, color, reverse }) => {
  return (
    <Wrapper reverse={reverse}>
      <Column>{data.image}</Column>
      <Column>
        <TextBox
          reverse={reverse}
          color={color}
          className="to-animate"
          data-sal="fade"
          data-sal-duration={600}
          data-sal-easing="ease"
          type="right_arrow"
        >
          <StyledHash>{data.hash}</StyledHash>
          <StyledHeader>{data.title}</StyledHeader>
          <StyledSubHeader>{data.subtitle}</StyledSubHeader>
          <StyledParagraph>{data.text}</StyledParagraph>
          <PrimaryButton type="right_arrow" to={data.slug}>
            Dowiedz się więcej
          </PrimaryButton>
        </TextBox>
      </Column>
    </Wrapper>
  )
}
