import React, {useEffect, useState} from "react"
import {CardsContainer, CopyContainer, StyledButtonWrap, StyledGlobalWrapper, StyledHeader, StyledSectionWrapper, StyledSubHeader} from "./styles"
import {Card} from "components/MenteeDetails"
import {PrimaryButton} from "components/Buttons"
import uuid from "react-uuid";

const OurMentees = ({data}) => {
  const [randomMentees, setRandomMentees] = useState([]);

  useEffect(() => {
    const randomIndexes = new Set();

    while (randomIndexes.size !== 3) {
      randomIndexes.add(Math.floor(Math.random() * data.length));
    }

    setRandomMentees([...randomIndexes].map(index => data[index]));
  }, [data]);

  return (
    <StyledGlobalWrapper>
      <StyledSectionWrapper>
        <CopyContainer>
          <StyledHeader>Nasi podopieczni</StyledHeader>
          <StyledSubHeader>Dzieci, z którymi pracujemy to wychowankowie placówki opiekuńczo-wychowawczej.</StyledSubHeader>
        </CopyContainer>
        <CardsContainer>
          {
            randomMentees.map((mentee) => (
              <Card
                key={uuid()}
                data-sal-easing="ease"
                menteeID={mentee.node.frontmatter.slug}
                mentee={mentee.node.frontmatter}
              />
            ))
          }
        </CardsContainer>
        <StyledButtonWrap>
          <PrimaryButton type="right_arrow" to="podopieczni">
            Zobacz wszystkich podopiecznych
          </PrimaryButton>
        </StyledButtonWrap>
      </StyledSectionWrapper>
    </StyledGlobalWrapper>
  );
};

export {OurMentees};
