import React, { useRef } from "react"
import CountUp from "react-countup"
import {
  Wrapper,
  CounterWrapper,
  ImageBox,
  CounterBox,
  StyledSpan,
  Count,
  ButtonWrapper,
} from "./styles"

import { PrimaryButton } from "components/Buttons"
import PlusSvg from "assets/img/PageHeader/x.svg"
import { TitleSvg } from "components/TitleSvg"
import useOnScreenOnce from "shared/Hooks/useOnScreenOnce"

export const Counter = () => {
  const counter = useRef(null)
  const onScreen = useOnScreenOnce(counter)
  return (
    <Wrapper>
      <CounterWrapper ref={counter}>
        <TitleSvg image={PlusSvg} duration="6s" right="-15%" bottom="0%" />
        <ImageBox />
        <CounterBox>
          <StyledSpan>Do tej pory wsparło nas:</StyledSpan>
          <Count>
            {onScreen ? (
              <CountUp
                start={0}
                end={568}
                duration={10}
                redraw={true}
              ></CountUp>
            ) : (
              "0"
            )}
          </Count>
          <StyledSpan>Darczyńców</StyledSpan>
        </CounterBox>
      </CounterWrapper>
      <ButtonWrapper>
        <PrimaryButton type="heart_with_arrow" to="/wesprzyj-nas">
          Podejmij z nami współpracę
        </PrimaryButton>
      </ButtonWrapper>
    </Wrapper>
  )
}
