import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import { SectionLayout } from "components/SectionLayout"
import { Container } from "./styles"

import PipeSvg from "assets/img/PageHeader/pipe.svg"
import PlusSvg from "assets/img/PageHeader/x.svg"
import CircleSvg from "assets/img/PageHeader/o.svg"
import { TitleSvg } from "components/TitleSvg"

export const NeedHelp = () => {
  const image = useStaticQuery(graphql`
    query {
      img: file(relativePath: { eq: "img/001-need-help.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  const data = {
    title: "Szukasz pomocy?",
    subtitle: "",
    text:
      "Jeśli potrzebujesz wsparcia, napisz do nas, a my pospieszymy z pomocą! Dajemy możliwość odbycia kursów i szkoleń, które pomogą rozwinąć skrzydła",
    slug: "/kontakt",
    hash: "światbezbarier",
    image: <Image fluid={image.img.childImageSharp.fluid} />,
  }
  return (
    <Container>
      <TitleSvg image={PipeSvg} duration="6s" right="36%" bottom="15%" />
      <TitleSvg image={PlusSvg} duration="6s" left="56%" top="45%" />
      <TitleSvg
        image={CircleSvg}
        duration="3s"
        right="28%"
        top="50%"
        rotation="70deg"
      />
      <SectionLayout data={data} reverse={true} color={"purple"} />
    </Container>
  )
}
