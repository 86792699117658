import React from "react"
import {Age, Avatar, CardContainer, CardCopy, CTABtn, DataContainer, Description, Img, Name, Title,} from "./styles"

const Card = ({mentee, menteeID}) => (
  <CardContainer colorBar={mentee.color} colorBarColor={mentee.color}>
    <CardCopy>
      <DataContainer>
        <Name as="h3">{mentee.name}</Name>
        <Age as="h3">{mentee.age}</Age>
      </DataContainer>
      <DataContainer>
        <Title as="h4">Chce zostać</Title>
        <Description>{mentee.wantToBe}</Description>
      </DataContainer>
      <DataContainer>
        <Title as="h4">Moje największe marzenie</Title>
        <Description>{mentee.dream}</Description>
      </DataContainer>
      <DataContainer>
        <Title as="h4">Najbardziej lubię</Title>
        <Description>{mentee.hobby}</Description>
      </DataContainer>
      <DataContainer>
        <CTABtn to={`/podopieczni/${menteeID}`} type="right_arrow">
          Poznaj mnie
        </CTABtn>
      </DataContainer>
    </CardCopy>
    <Avatar>
      <Img src={mentee.avatar.publicURL} alt={`${mentee.name} avatar`}/>
    </Avatar>
  </CardContainer>
);

export {Card};
