import styled from "styled-components"
import { SectionWrapper } from "shared/Styles/Wrappers"

import { Link } from "gatsby"

export const Container = styled.div`
  padding: 96px 0;
  background-color: #f6f6f6;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 64px 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 48px 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    padding: 48px 0;
  }
`

export const Wrapper = styled(SectionWrapper)`
  position: relative;
`

export const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 72px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: 48px;
  }
`

export const EventsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const Date = styled.span`
  display: block;
  font-size: 14px;
  font-weight: 700;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 12px;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_small}) {
    font-size: 10px;
  }
`

export const Title = styled.div`
  display: block;
  font-size: 20px;
  font-weight: 700;
  margin-top: 8px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 16px;
    margin-top: 6px;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_small}) {
    font-size: 14px;
  }
`

export const ImageBox = styled.div`
  position: relative;
  &:after {
    content: "zobacz";
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(26, 26, 26, 0.6);
    z-index: 2;
    color: #fff;
    font-size: 30px;
    transition: 0.2s opacity ease;
    opacity: 0;
  }
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    &:after {
      font-size: 24px;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    &:after {
      display: none;
    }
  }
`

export const Text = styled.div`
  padding: 32px 18px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 24px 16px;
  }
`

export const EventItem = styled(Link)`
  width: calc(50% - 24px);
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  display: inline-block;
  &:hover {
    ${ImageBox} {
      &:after {
        opacity: 1;
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: calc(50% - 18px);
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_small}) {
    width: calc(50% - 9px);
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
`

export const ButtonWrapper = styled.div`
  text-align: center;
  padding-top: 64px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding-top: 48px;
  }
`
