import { useState, useEffect } from "react"

const useOnScreen = function (ref, rootMargin = "0px") {
  const [isIntersecting, setIntersecting] = useState(false)
  const [visibleOnce, setVisibleOnce] = useState(false)

  useEffect(() => {
    const reference = ref.current
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting)
        if (entry.isIntersecting) {
          setVisibleOnce(true)
        }
      },
      {
        rootMargin,
      }
    )

    if (reference) {
      observer.observe(reference)
    }
    return () => {
      observer.unobserve(reference)
    }
  }, [ref, rootMargin])

  return visibleOnce
}

export default useOnScreen
