import {
  SectionWrapper
} from "shared/Styles/Wrappers"
import styled
  from "styled-components"

export const Container = styled.div`
    padding: 96px 0;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        padding: 64px 0;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        padding: 48px 0;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        padding: 48px 0;
    }
`

export const Wrapper = styled(SectionWrapper)``

export const ImagesWrapper = styled.div`
    display: flex;
    justify-content: center;

    div {
        max-width: 240px;
        max-height: 140px;
        width: 100%;
        height: 100%;

        &:not(:last-child) {
            margin-right: 32px;
        }

        @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
            max-width: 220px;
            max-height: 130px;

            &:not(:last-child) {
                margin-right: 24px;
            }
        }
        
        @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
            max-width: 200px;
            max-height: 110px;

            &:not(:last-child) {
                margin-right: 16px;
            }
        }
    }
`;

export const ImageBox = styled.div`
    .gatsby-image-wrapper {
        max-width: 100%;
        max-height: 100%;
    }
`
