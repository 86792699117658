import styled from "styled-components"
import { SectionWrapper } from "shared/Styles/Wrappers"

import BoyIcon from "assets/img/svg/boy.svg"

export const Wrapper = styled(SectionWrapper)`
  padding: 96px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 64px 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 48px 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    padding: 48px 0;
  }
`

export const CounterWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

export const ImageBox = styled.div`
  width: 200px;
  height: 200px;
  background-image: url(${BoyIcon});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: 180px;
    height: 180px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    width: 100px;
    height: 100px;
  }
`

export const CounterBox = styled.div`
  background: #5618a4;
  border-radius: 30px;
  padding: 48px 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  color: #fff;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 36px 18px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    padding: 36px 24px;
  }
`

export const StyledSpan = styled.span`
  font-size: 18px;
  font-weight: 700;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 16px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    font-size: 12px;
  }
`

export const Count = styled.span`
  font-size: 70px;
  line-height: 1;
  font-weight: 700;
  display: inline-block;
  margin: 32px 0 18px 0;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 60px;
    margin: 24px 0 18px 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    font-size: 40px;
    margin: 12px 0;
  }
`

export const ButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin: 64px 0;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    margin: 36px 0;
  }
`
