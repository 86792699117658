import React, { useEffect, useRef } from "react"
import { Iframe, IframeWrapper } from "./style"

export const CONTACT_IFRAME_NAME = "CHARITY_CONTACT_FORM"

const Form = ({ formURL }) => {
  const iframe = useRef(null)
  const iframeWrapper = useRef(null)

  const resizeWindow = useRef(event => {
    if (
      iframe.current &&
      event.origin.startsWith(process.env.GATSBY_EXTERNAL_FORMS_ORIGIN) &&
      event.data.height &&
      event.data.name === CONTACT_IFRAME_NAME
    ) {
      iframe.current.style.height = event.data.height + "px"
    }
  })

  useEffect(() => {
    const reference = resizeWindow.current

    window.addEventListener("message", reference)

    return () => {
      window.removeEventListener("message", reference)
    }
  }, [resizeWindow])

  return (
    <IframeWrapper ref={iframeWrapper}>
      <Iframe
        title="Formularz kontaktowy"
        ref={iframe}
        src={formURL}
        name={CONTACT_IFRAME_NAME}
      />
    </IframeWrapper>
  )
}

export { Form }
