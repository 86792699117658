import {
  HeaderWithNav
} from "components/HeaderWithNav"
import React
  from "react"
//hooks
import {
  useSponsorsPhotos
} from "shared/Hooks/SponsorsSlider"
import {
  ImagesWrapper
} from './styles';
//styles
import {
  Container,
  ImageBox,
  Wrapper
} from "./styles"

export const Partners = () => {
  const logos = useSponsorsPhotos()

  return (
    <Container id="patroni">
      <Wrapper>
        <HeaderWithNav
          title="Dumnie wspierają nas"
          prevClass="sponsors-slider-next"
          nextClass="sponsors-slider-prev"
        ></HeaderWithNav>
        <ImagesWrapper>
          {logos.map((logo, key) => (
            <ImageBox>{logo.image}</ImageBox>
          ))}
        </ImagesWrapper>
      </Wrapper>
    </Container>
  )
}
