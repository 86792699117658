import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Form } from "./form"
import bgForm from "assets/img/bg-form.png"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import { SectionWrapper } from "shared/Styles/Wrappers"
import { Header } from "components/Typography"
import { TitleSvg } from "../TitleSvg/titleSvg"
import PipeSvg from "assets/img/PageHeader/pipe.svg"
import PlusSvg from "assets/img/PageHeader/x.svg"

const Wrapper = styled.div`
  background-image: url(${bgForm});
  background-repeat: no-repeat;
  background-position: top left;
  background-color: #5618a4;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 96px 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 64px 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 48px 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    padding: 48px 0;
  }
`

const HeadingWrapper = styled.div`
  margin-bottom: 64px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: 48px;
  }
`

const ImageWrapper = styled.div`
  width: 100%;

  .gatsby-image-wrapper {
    max-width: 100%;
    max-height: 100%;
  }
`

const Col = styled.div`
  width: 50%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
  }
`

const ColLeft = styled(Col)`
  display: flex;
  justify-content: flex-end;
`

const ColRight = styled(Col)`
  display: flex;
  position: relative;

  .gatsby-image-wrapper {
    margin-left: auto;
    margin-right: auto;
  }
`

const CustomGrid = styled(SectionWrapper)`
  width: 100%;
  display: flex;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    flex-direction: column-reverse;
  }
`

export const FormContainer = ({ subject }) => {
  const image = useStaticQuery(graphql`
    query {
      img: file(relativePath: { eq: "img/001-form.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  const [formUrl, setFormUrl] = useState(process.env.GATSBY_CONTACT_FORM_URL)

  useEffect(() => {
    if (subject) {
      const builder = new URL(process.env.GATSBY_CONTACT_FORM_URL)
      builder.searchParams.append("subject", subject)

      setFormUrl(builder.href)
    }
  }, [subject, setFormUrl])

  return (
    <Wrapper>
      <HeadingWrapper>
        <Header as="h3" color="#fff">
          Skontaktuj się!
        </Header>
      </HeadingWrapper>
      <CustomGrid>
        <ColLeft>
          <Form formURL={formUrl} />
        </ColLeft>
        <ColRight>
          <TitleSvg image={PlusSvg} top="0%" right="10%" duration="5s" />
          <TitleSvg
            image={PipeSvg}
            top="-10%"
            left="40%"
            duration="6s"
            rotation="90deg"
          />
          <TitleSvg image={PlusSvg} bottom="20%" left="20%" duration="5s" />
          <ImageWrapper>
            <Image fluid={image.img.childImageSharp.fluid} />
          </ImageWrapper>
        </ColRight>
      </CustomGrid>
    </Wrapper>
  )
}
