import React from "react"
import {Age, Avatar, CardContainer, CardCopy, Description, DetailedText, Img, Name, SingleMenteeDataContainer, SquareButton, Title,} from "./styles"
import {Icon} from "components/Icons"

const MenteeDetails = ({mentee}) => (
  <CardContainer className="singleMenteeCard" colorBar={mentee.color} colorBarColor={mentee.color}>
    <Avatar>
      <Img src={mentee.avatar.publicURL} alt={`${mentee.name} avatar`}/>
    </Avatar>
    <CardCopy>
      <SingleMenteeDataContainer>
        <Name as="h3" color="#000">
          {mentee.name}
        </Name>
        <Age as="h3" color="#000">
          {mentee.age}
        </Age>
      </SingleMenteeDataContainer>
      <SingleMenteeDataContainer>
        <Title as="h3" fontSize="18px" color="#000">
          Chce zostać
        </Title>
        <Description fontSize="14px" color="#000">
          {mentee.wantToBe}
        </Description>
      </SingleMenteeDataContainer>
      <SingleMenteeDataContainer>
        <Title as="h3" fontSize="18px" color="#000">
          Moje największe marzenie
        </Title>
        <Description fontSize="14px" color="#000">
          {mentee.dream}
        </Description>
      </SingleMenteeDataContainer>
      <SingleMenteeDataContainer>
        <Title as="h3" fontSize="18px" color="#000">
          Najbardziej lubię
        </Title>
        <Description fontSize="14px" color="#000">
          {mentee.hobby}
        </Description>
      </SingleMenteeDataContainer>
    </CardCopy>
    <DetailedText>
      <Title as="h3" fontSize="18px" color="#000">
        O mnie
      </Title>
      <Description fontSize="14px" color="#000">
        {mentee.about}
      </Description>
    </DetailedText>
    <SquareButton to={`${process.env.GATSBY_SUPPORT_PAGE}/podopieczni/${mentee.slug}`}>
      <Icon type="heart" color="#fff" width="54px" height="48px"/>
      Wspieraj
    </SquareButton>
  </CardContainer>
);

export {MenteeDetails};
