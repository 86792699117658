import {
  graphql,
  useStaticQuery
} from "gatsby"
import Image
  from "gatsby-image"
import React
  from "react"

export const useSponsorsPhotos = () => {
  const query = useStaticQuery(graphql`
      query {
          sponsor1: file(
              relativePath: { eq: "img/Homepage/sponsors/iv-logo.png" }
          ) {
              childImageSharp {
                  fluid(maxWidth: 240, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          sponsor2: file(
              relativePath: { eq: "img/Homepage/sponsors/Cool-Gym.jpg" }
          ) {
              childImageSharp {
                  fluid(maxWidth: 240, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
      }
  `)

  return [
    {
      image: <Image fluid={query.sponsor1.childImageSharp.fluid}/>,
    },
    {
      image: <Image fluid={query.sponsor2.childImageSharp.fluid}/>,
    },
  ];
}
