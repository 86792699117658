import React, {useRef} from "react"
import {graphql, useStaticQuery} from "gatsby"
import Image from "gatsby-image"

import {SectionLayout} from "components/SectionLayout"
import {Container, PopupWrapper} from "./styles"
import PlusSvg from "assets/img/PageHeader/x.svg"
import {TitleSvg} from "components/TitleSvg"

// import {useOnScreen} from "shared/Hooks/useOnScreen"

export const About = () => {
  const aboutRef = useRef(null)
  // const showPopup = useOnScreen(aboutRef)

  const query = useStaticQuery(graphql`
    query {
      aboutus: file(relativePath: { eq: "img/Homepage/aboutus.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  const data = {
    title: "Zobacz, jak wygląda świat bez barier!",
    subtitle: "Niemożliwe dla nas nie istnieje!",
    text:
      "Świat Bez Barier to fundacja dla dzieci, która od czerwca 2020 roku zapewnia lepszy start w dorosłość. Uważamy, że wiedza otwiera świat, dlatego zapewniamy dostęp do kursów, szkoleń i zajęć oraz stażów i praktyk zawodowych.",
    slug: "/o-nas",
    hash: "światbezbarier",
    image: <Image fluid={query.aboutus.childImageSharp.fluid} />,
  }
  return (
    <Container>
      <TitleSvg image={PlusSvg} duration="3s" left="40%" bottom="15%" />
      {/*<Popup showPopup={showPopup} />*/}
      <SectionLayout data={data} />
      <PopupWrapper ref={aboutRef} />
    </Container>
  )
}
