import styled, {css} from "styled-components"
import {Link} from "gatsby"
import {Paragraph, SubHeader} from "components/Typography"
import {PrimaryButton} from "components/Buttons"

// desktop first
export const Name = styled(SubHeader)`
    font-size: 28px;
    color: ${({theme}) => theme.colors.fontSecondary};
    font-weight: 600;
    text-align: left;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: 20px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        font-size: 22px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        font-size: 16px;
    }
`;

export const Age = styled(SubHeader)`
    font-size: 20px;
    color: ${({theme}) => theme.colors.fontSecondary};
    font-weight: 500;
    font-style: italic;
    text-align: left;
    margin-top: 4px;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: 14px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        font-size: 12px;
        font-weight: 400;
    }
`;

export const Title = styled(SubHeader)`
    font-size: 18px;
    margin-bottom: 4px;
    color: #000;
    font-weight: 600;
    text-align: left;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: 14px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        font-size: 12px;
    }
`;

export const Description = styled(Paragraph)`
    font-size: 14px;
    color: #000;
    font-weight: 300;
    text-align: left;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: 12px;
    }
`;

export const CardCopy = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
`;

export const DataContainer = styled.div`
    margin-bottom: 12px;

    &:first-child {
        margin-bottom: 2rem;
    }

    &:last-child {
        padding: 1rem 0;
        margin-bottom: 0;
    }
`;

export const Avatar = styled.div`
    width: 140px;
    position: absolute;
    top: 24px;
    right: 24px;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        width: 80px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        width: 80px;
        height: 80px;
    }
`;

export const Img = styled.img`
    max-height: 100%;
    max-width: 100%;
`;

export const CTABtn = styled(PrimaryButton)`
    margin: 1rem 0;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        font-size: 12px;
    }
`;

export const CardContainer = styled.div`
    width: 31%;
    margin: 1% 1%;
    padding: 2%;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    align-items: flex-start;
    overflow: hidden;
    position: relative;

    ${({colorBar}) => colorBar && css`
        &::before {
            content: "";
            background-color: ${({colorBarColor, theme}) => colorBarColor ? colorBarColor : theme.colors.primary};
            width: 100%;
            height: 5px;
            position: absolute;
            top: 0;
            left: 0;
        }
    `}
    &.singleMenteeCard {
        width: 100%;
        justify-content: space-between;
        flex-direction: column;
        padding: 2% 3% 5%;

        @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
            flex-direction: row;
            align-items: center;

            ${Avatar} {
                align-self: flex-start;
                height: 100%;
                width: 150px;
                position: unset;
            }
        }

        ${Avatar} {
            top: 3%;

            @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
                width: 150px;
                height: 150px;
            }

            @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
                width: 100px;
                height: 100px;
            }
        }

        @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
            padding: 24px 24px 5%;
        }
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        width: 46%;
        margin: 2% 2%;
        padding: 5%;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        width: 100%;
        margin: 5% 1%;
    }
`;

export const SingleMenteeDataContainer = styled.div`
    margin: 0.8rem;
`;

export const DetailedText = styled.div`
    margin: 0.8rem;

    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
        max-width: 40%;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        margin: 0.7rem;
    }
`;

export const SquareButton = styled(Link)`
    background-color: ${({theme}) => theme.colors.accentColor};
    border-radius: 10px;
    width: 120px;
    height: 125px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    color: white;

    svg {
        margin: 0.3rem 0;
    }

    @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
        max-width: 40%;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        width: 110px;
        height: 113px;
        font-size: 13px;

        svg {
            width: 40px;
        }
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        flex-direction: row;

        svg {
            width: 20px;
            margin: 0 0.5rem;
        }
    }

    .singleMenteeCard & {
        margin: 0 2rem;

        @media screen and (min-width: ${({theme}) => theme.breakpoints.tablet}) {
            margin-top: 0;
        }

        @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
            width: 150px;
            height: 50px;
            font-size: 14px;
            margin: 1rem 0;
        }
    }
`;
