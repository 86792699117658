import styled from "styled-components"
import { GlobalWrapper, SectionWrapper } from "shared/Styles/Wrappers"
import { Header, SubHeader } from "components/Typography/"

export const StyledGlobalWrapper = styled(GlobalWrapper)`
  background-color: #5618a4;
  background-image: url("data:image/svg+xml,%3Csvg width='1417' height='1080' viewBox='0 0 1417 1080' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-166.957 1437.76C-97.9723 1499.67 0.287216 1536.13 91.9231 1515.05C202.658 1489.57 277.641 1390.33 331.226 1292.73C384.81 1195.13 430.569 1087.97 519.946 1019.37C679.803 896.67 914.411 941.211 1104.34 869.856C1228.35 823.278 1330.68 724.598 1379.5 604.472C1561.42 156.988 1036.69 -248.506 618.977 -255.04C281.781 -260.293 -39.5351 -61.3645 -221.381 215.122C-394.799 478.834 -423.078 836.043 -334.166 1136.12C-327.186 1159.68 -319.485 1182.91 -311.062 1205.71C-279.319 1291.6 -235.893 1375.87 -166.957 1437.76Z' fill='%236121B2'/%3E%3C/svg%3E%0A");
  background-position: 30% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 96px 0;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 64px 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 48px 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    padding: 48px 0;
  }
`
export const StyledSectionWrapper = styled(SectionWrapper)`
  display: flex;
  flex-direction: column;
`
export const CopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  text-align: center;
`
export const StyledHeader = styled(Header)`
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 24px;
`
export const StyledSubHeader = styled(SubHeader)`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 48px;
`

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    & > div {
      &:last-child {
        display: none;
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    & > div {
      &:last-child {
        display: initial;
      }
    }
  }
`
export const StyledButtonWrap = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 48px;
`
