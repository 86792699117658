import React                 from "react"
import {useHeroSliderPhotos} from "./useHeroSliderPhotos"

export const useHeroSliderData = () => {
  const slides = useHeroSliderPhotos();
  
  return [
    {
      title:      (
                    <>
                      Poznaj naszych <br/>podopiecznych
                    </>
                  ),
      pagination: "Poznaj naszych podopiecznych",
      subtitle:
                  "Jeśli chcesz razem z nami pomóc rozwinąć skrzydła, wpłać kwotę wsparcia.",
      slug:       "/podopieczni",
      image:      slides.slide1,
    },
    {
      title:      (
                    <>
                      Zobacz jak możesz<br/>nas wspomóc
                    </>
                  ),
      pagination: "Komu pomagamy?",
      subtitle:
                  "Jeśli chcesz razem z nami pomóc rozwinąć skrzydła, wpłać kwotę wsparcia.",
      slug:       "/wesprzyj-nas",
      image:      slides.slide2,
    },
    {
      title:           (
                         <>
                           Szukasz pomocy?
                         </>
                       ),
      pagination:      "Szukasz pomocy?",
      subtitle:
                       "Jeśli chcielibyście lepszej perspektywy na przyszłość dla swojego dziecka lecz niestety Wasza sytuacja finansowa, Wam na to nie pozwala - skontaktuj się z nami, chętnie pomożemy!",
      slug:            "/kontakt",
      image:           slides.slide3,
      backgroundImage: true,
    },
  ]
}
