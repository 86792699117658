import {graphql, useStaticQuery} from "gatsby"

export const useHeroSliderPhotos = () => {
  const data = useStaticQuery(graphql`
    query {
      slide1: file(relativePath: { eq: "img/Homepage/slides/slide-1.png" }) {
        childImageSharp {
          fluid(maxHeight: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      slide2: file(relativePath: { eq: "img/Homepage/slides/slide-2.png" }) {
        childImageSharp {
          fluid(maxHeight: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      slide3: file(relativePath: { eq: "img/Homepage/slides/slide-3.png" }) {
        childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      slide4: file(relativePath: { eq: "img/Homepage/slides/slide-5.png" }) {
        childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  return data
}
