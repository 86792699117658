import styled from "styled-components"
import { SectionWrapper } from "shared/Styles/Wrappers"

import {
  Header,
  Paragraph,
  SubHeader,
  HashHeader,
} from "../../components/Typography"

export const Wrapper = styled(SectionWrapper)`
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
  position: relative;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    flex-direction: column;
  }
`

export const Column = styled.div`
  width: 50%;
  display: flex;
  padding: 60px;
  align-items: center;
  justify-content: center;
  .gatsby-image-wrapper {
    width: 100%;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 20px;
    width: 50%;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    width: 100%;
    padding: 0;
    .gatsby-image-wrapper {
      max-width: 300px !important;
    }
  }
`

export const StyledHash = styled(HashHeader)``

export const StyledHeader = styled(Header)`
  margin: 48px 0;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    margin: 32px 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin: 24px 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    margin: 12px 0 24px 0;
  }
`

export const StyledSubHeader = styled(SubHeader)``

export const StyledParagraph = styled(Paragraph)`
  margin: 24px 0 64px 0;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    margin: 18px 0 36px 0;
  }
`

export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ reverse }) => (reverse ? "flex-end" : "flex-start")};
  text-align: ${({ reverse }) => (reverse ? "right" : "left")};
  max-width: 620px;
  width: 100%;
  ${StyledHash},
  ${StyledHeader},
  ${StyledSubHeader},
  ${StyledParagraph} {
    color: ${({ color }) => (color ? "#fff" : "#000")};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    max-width: 520px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    margin-top: 24px;
    align-items: flex-start !important;
    text-align: left !important;
  }
`
