import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import { SectionLayout } from "components/SectionLayout"
import { Container } from "./styles"
import CircleSvg from "assets/img/PageHeader/o.svg"
import PlusSvg from "assets/img/PageHeader/x.svg"
import { TitleSvg } from "components/TitleSvg"

export const Help = () => {
  const query = useStaticQuery(graphql`
    query {
      photo: file(relativePath: { eq: "img/Homepage/skaczace-dzieci.png" }) {
        childImageSharp {
          fluid(maxWidth: 340, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  const data = {
    title: "Jak możesz pomóc?",
    subtitle:
      "Jeśli chcesz razem z nami pomóc rozwinąć skrzydła, wpłać kwotę wsparcia.",
    text:
      "Chcesz pomóc spełniać dziecięce marzenia? Również dzięki Tobie może pojawić się uśmiech na twarzy naszych podopiecznych.  ",
    slug: "https://wsparcie.fundacjaswiatbezbarier.pl/",
    hash: "światbezbarier",
    image: (
      <Image objectFit="contain" fluid={query.photo.childImageSharp.fluid} />
    ),
  }
  return (
    <Container>
      <TitleSvg
        image={PlusSvg}
        duration="3s"
        left="29%"
        top="20%"
        rotation="30deg"
      />
      <TitleSvg
        image={CircleSvg}
        duration="4s"
        type="circle"
        left="25%"
        bottom="30%"
        rotation="100deg"
      />
      <SectionLayout data={data} />
    </Container>
  )
}
