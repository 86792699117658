import styled from "styled-components"

export const IframeWrapper = styled.div`
  overflow: auto;
  max-width: 500px;
  width: 100%;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    max-width: 450px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 350px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    max-width: 100%;
    margin-top: 24px;
  }
`
export const Iframe = styled.iframe`
  height: 300px;
  width: 100%;
  display: block;
  border: none;
`
