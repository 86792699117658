import React             from "react"
import SEO               from "components/seo"
import { FormContainer } from "components/Form"
import {NewsSlider}      from "src/components/NewsSlider";
import {
  About,
  Counter,
  Help,
  Hero,
  NeedHelp,
  OurMentees,
  Partners,
}                        from "../sections/Homepage/"
import { graphql } from "gatsby"

const IndexPage = ({ data }) => (
  <>
    <SEO
      title={"Strona główna"}
      description={
        "Świat Bez Barier to fundacja dla dzieci, która od czerwca 2020 roku zapewnia lepszy start w dorosłość."
      }
    />
    <Hero />
    <About />
    <OurMentees data={data.allMdx.edges} />
    <Help />
    <NeedHelp />
    <Partners />
    <Counter />
    <NewsSlider title="Aktualności"/>
    <FormContainer />
  </>
)

export const query = graphql`
  {
    allMdx {
      edges {
        node {
          id
          slug
          frontmatter {
            name
            age
            wantToBe
            avatar {
              publicURL
            }
            about
            color
            dream
            hobby
            slug
            title
          }
        }
      }
    }
  }
`

export default IndexPage
